import { FCI_APIS } from '~implementations/fci/constants/apis';

const GENERAL_APIS = {
  CORE: {
    CITY: '/api/core/city/',
    DEPARTMENT: '/api/core/department/',
    HOUSE_REFERENCE: '/api/people/house-reference/',
    IPS: '/api/core/ips/',
    NAV_BAR: '/api/core/nav-bar/',
    VERIFY_2FA: '/api/core/verify-2fa/ ',
    COUNTRY: '/api/core/country/',
    USER: '/api/core/users/'
  },
  MANAGE_USERS: {
    USERS_QUERY: '/api/manage-users/users-query/',
    GROUPS: '/api/manage-users/groups/',
    PRIVILEGES: '/api/manage-users/privileges/',
    USERS: '/api/manage-users/users/',
    USER_ACTION: '/api/manage-users/user-action/'
  },
  APPOINTMENT: {
    INTERNAL_PAYER: (search: string) => `/api/lacardio/agreements/${search}/`,
    BOOKING: '/api/appointment/booking/',
    CANCEL_REASONS: '/api/appointment/cancel-reasons/',
    BOOKING_RANGE: '/api/appointment/booking-range/',
    BOOKING_BY_DATE: '/api/appointment/booking-patients-by-date/',
    BOOKING_SPACE: '/api/appointment/booking-space/',
    CHECK: '/api/appointment/check/'
  },
  MASSIVE_LOAD: {
    FILE_REQUESTS: '/api/massive_load/file-requests/',
    FILE_TEMPLATE: '/api/massive_load/file-template/',
    PRESIGNED_URLS: '/api/massive_load/presigned-urls/',
    REQUEST_COMPRESS_RAR: '/api/massive-load/requests/rar/',
    REQUEST_COMPRESS_ZIP: '/api/massive-load/requests/zip/',
    REQUEST_CSV: '/api/massive-load/requests/csv/',
    REQUEST_RESULT: '/api/massive_load/request-results/',
    REQUEST_S3: '/api/massive_load/requests/s3/',
    REQUEST_TXT: '/api/massive-load/requests/txt/',
    REQUEST_XLS: '/api/massive-load/requests/xls/',
    REQUEST_XLSX: '/api/massive-load/requests/xlsx/',
    TEMPLATES: '/api/massive_load/templates/'
  },
  NOTIFICATIONS: {
    ALERTS: '/api/notifications/alerts/',
    ALERTS_NOVELTY: '/api/notifications/alerts-novelty/',
    NOTIFICATION_SERVICE_PROVIDER:
      '/api/notifications/notification-service-provider/'
  },
  LOGIN: '/api/login/',
  LOGOUT: '/api/logout/',
  TOKEN: '/api/token/',
  TOKEN_REFRESH: 'api/token/refresh/',
  UFCA: {
    ANSWER: '/api/ufcas/answer/',
    ANSWERS: '/api/ufcas/answers/',
    ANSWERS_SOURCE: '/api/ufcas/answers-source/',
    MODULE_WITH_QUESTIONS: '/api/ufcas/module-with-questions/',
    SURVEYS: '/api/ufcas/surveys/',
    SURVEYS_DETAIL: '/api/ufcas/surveys-detail/',
    SURVEY_MODULES: '/api/ufcas/modules/',
    SURVEY_WORKUNIT_CONFIG: (surveyPk: number) =>
      `/api/ufcas/survey-workunit-config/${surveyPk}/`,
    SURVEY_FINALIZED_FORM: '/api/ufcas/finalized-form/',
    SURVEY_PATIENT: '/api/ufcas/survey-patient/',
    SURVEY_PATIENT_ANSWERS: '/api/ufcas/survey-patient-answers/',
    OPTIONS: '/api/ufcas/options/',
    PATIENT_BASE: '/api/people/patient-base/',
    PATIENT_BASE_WITH_DOCUMENT: '/api/people/patient-base-with-document/',
    QUESTIONS: '/api/ufcas/question/',
    QUESTION_FILTERS: '/api/ufcas/question-filters/',
    TRANSCRIBE: '/api/ufcas/transcribe-fill/',
    TRANSCRIBE_STATUS: '/api/ufcas/transcribe-status/',
    PRESIGNED_AUDIO_URL: '/api/ufcas/presigned-transcribe-audio/'
  },
  PEOPLE: {
    PATIENT: '/api/people/patient/',
    DOCUMENT_TYPES: '/api/people/document-type/'
  },
  CHEMO: {
    CYCLE: (uuid: string) => `/api/chemo/cycle/${uuid}`,
    CYCLE_REPROGRAMMING: (uuid: string) =>
      `/api/chemo/re-programming-patient/${uuid}`,
    PATIENT_CHEMO: '/api/chemo/patient_chemo/',
    REPROGRAMMING: 'api/people/re-programming/',
    PROGRAMMING_PATIENT: 'api/chemo/programming-patient',
    ROOMS: '/api/chairs_chemo/room/',
    CHAIR_VALIDATION: '/api/chairs_chemo/room/validation-chair/',
    PROTOCOLS: '/api/chemo/protocol/',
    CREATE_CYCLE: '/api/chemo/cycle/create/',
    UPDATE_CYCLE: (id: string) => `/api/chemo/cycle/update/${id}/`,
    APPLICATIONS: (patientId: string) =>
      `/api/chemo/application_without_associated_cycle/${patientId}/`,
    HEAD_OF_PROGRAMMING: '/api/chemo/application/head-of-programming/',
    PHARMACEUTICAL_CHEMISTS: '/api/chemo/application/pharmaceutical-chemist/',
    APPLICATION_UPDATE_STATUS: '/api/chemo/application-update-status/',
    APPLICATION_VALIDATION_CREATE: '/api/chemo/application-validation-create/',
    CANCEL_APPLICATION: '/api/chemo/cancel-application/',
    APPLICATION: '/api/chemo/application/'
  },
  MEDICAL_ORDERS: {
    MEDICAL_ORDERS: (patientId: string) =>
      `/api/medical_order/medical_order_without_associated_application/${patientId}/`
  },
  ROOM: {
    LOCATION_CREATE: '/api/room/location-create/',
    LOCATION_DESTROY: '/api/room/location-destroy/',
    LOCATIONS: '/api/room/locations/',
    PATIENT_LOCATION_CHANGE: (
      oldLocation: number,
      newLocation: number,
      patientUUID: string
    ) =>
      `/api/room/patient-location-change/${oldLocation}/${newLocation}/${patientUUID}/`,
    PACKETS: (cohort_id: number, patient_uuid: string) =>
      `/api/room/cohort/${cohort_id}/patient/${patient_uuid}/packets/`,
    PATIENT_ROOM: (cohort_id: string, room_id: number) =>
      `/api/room/patient-room/${cohort_id}/room/${room_id}`,
    PATIENTS_WITHOUT_ROOM: (cohort_id: string) =>
      `/api/room/patients-without-room/${cohort_id}/`,
    ROOM_UPDATE: '/api/room/room-update/',
    ROOMS: '/api/room/rooms/',
    SUBMIT_LOCATION: '/api/room/submit-location/'
  },
  WORK_LIST: {
    COHORT: '/api/cohort/cohorts/',
    GROUP: '/api/core/permissions-group/'
  },
  COHORT: {
    FORM_FIELDS: '/api/cohort/dynamic-forms/field-types/',
    MANAGE_COHORT: (cohort_id: number, object_id: number) =>
      `/api/manage/cohort/${cohort_id}/object/${object_id}/submit-ufca/`,
    CANCEL_PACKAGE: (cohort_id: number, package_id: number) =>
      `/api/manage/cohort/${cohort_id}/cancel-packet/${package_id}/`,
    COHORTS: '/api/manage/cohorts/',
    ACTIVITIES: (cohort_id: number, package_id: number) =>
      `/api/manage/control_tower/cohort/activity/${cohort_id}/packet/${package_id}/`,
    ADD_CLINIC_HISTORY: (cohort_id: number, package_id: number) =>
      `/api/manage/control_tower/cohort/${cohort_id}/packet/${package_id}/extras`,
    ACTIVITIES_GROUPED: (cohort_id: number, state: number) =>
      `/api/manage/cohort/${cohort_id}/state/${state}/patients/`,
    ADD_ACTIVITIES: '/api/manage/control_tower/cohort/activity/add/',
    COHORT_LOCATION_TABLE: (cohort_id: number, location_id: number) =>
      `/api/room/cohort/${cohort_id}/location/${location_id}/`,
    COHORT_STATES: (pk: number) => `/api/manage/cohort/${pk}/states/`,
    COHORT_DYNAMIC_FILTER: (pk: number) =>
      `/api/manage/dynamic-filter/state/${pk}/`,
    COHORT_STATES_DATA: (pk: number, state: number) =>
      `/api/manage/cohort/${pk}/data/${state}/`,
    COHORT_CHANGE_STATE: (id: number) =>
      `/api/manage/cohort/submit-transaction/${id}/`,
    COHORT_CONTROL_TOWER_CONFIG: (id: number) =>
      `/api/manage/control_tower/cohort/${id}/config/`,
    COHORT_CONTROL_PACKETS: (cohort_id: number) =>
      `/api/manage/control_tower/cohort/${cohort_id}/packets/`,
    ADDITIONAL_ACTIVITIES: (cohort_id: number, packet_id: number) =>
      `api/manage/control_tower/list/additional/activities/cohort/${cohort_id}/packet/${packet_id}`,
    COHORT_CHANGE_ROWS_STATE: `/api/manage/cohort/submit-transactions/`,
    AUTOMATIC_ASSIGNED_USER: '/api/cohort/automatic_assigned_user/',
    AUTOMATIC_ASSIGNED_GROUP: '/api/cohort/automatic_assigned_group/',
    MANUAL_ASSIGNED_USER: '/api/cohort/assigned_user/',
    MANUAL_ASSIGNED_GROUP: '/api/cohort/assigned_group/',
    COHORT_OBJECTS: (cohort_id: number) => `/api/cohort/${cohort_id}/objects/`
  },
  REPORTS: {
    DYNAMIC_REPORTS: '/api/reports/dynamic-reports/',
    REPORTS: '/api/reports/reports/',
    REQUESTS: '/api/reports/requests/'
  },
  POWER_BI: {
    DASHBOARD: (dashboardUUID: string) =>
      `/api/power_bi/reports/${dashboardUUID}/`
  }
} as const;

export const APIS = {
  ...GENERAL_APIS,
  ...FCI_APIS
};
